.a-ab-op-7--btn {
    @include flexJustifyCenter;

    background-color: $white;
    position: absolute;
    bottom: 0;
    height: 40px;
    display: flex;
    flex-direction: initial;
    width: 100%;;
    align-items: center;
    z-index: 1;

    &:after {
        @include homeBtnHeightAnimtions;

        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $violet;
    }
}