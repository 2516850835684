@import './_transitions.scss';
@import './_colors.scss';
@import './_reset.scss';
@import './_flex.scss';
@import './_sizing.scss';
@import './_button.scss';
@import './_position.scss';
@import './_borders.scss';
@import './_backgrounds.scss';
@import './_text.scss';
@import './_display.scss';
@import './_spacing.scss';
@import './_align.scss';
@import './_fonts.scss';

@mixin transitionCubic {
    -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@mixin transitionCustomEase {
    -moz-transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
    -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -moz-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

@mixin backgroundSizeDefault {
    background-size: cover;
    background-position: center top;
}

@mixin skew($degrees) {
    -webkit-transform: skew($degrees);
    -moz-transform: skew($degrees);
    -ms-transform: skew($degrees);
    -o-transform: skew($degrees);
    transform: skew($degrees);
}

@keyframes jumpVertical {
    0% {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
        opacity: 1;
    }

    25% {
        -webkit-transform: translateY(10px) scale(0.9);
        -ms-transform: translateY(10px) scale(0.9);
        transform: translateY(10px) scale(0.9);
        opacity: 0;
    }

    26% {
        -webkit-transform: translateY(-10px) scale(0.9);
        -ms-transform: translateY(-10px) scale(0.9);
        transform: translateY(-10px) scale(0.9);
        opacity: 0;
    }

    60% {
        -webkit-transform: translateY(0) scale(1);
        -ms-transform: translateY(0) scale(1);
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

@keyframes clip {
    0% {
        transform: translate(-250px, -70px);
        fill: #99db28;
    }

    95% {
        fill: #99db28;
    }

    100% {
        transform: translate(0, -230px);
        fill: #dae9c9;
    }
}

.ie9 img[src$='.svg'] {
    width: 100%;
}

/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src$='.svg'] {
        width: 100%;
    }
}

svg#Layer_1 {
    width: 100%;
    height: calc(100vh - 30px - 87px - 10px);
    margin-top: calc(30px + 87px + 10px - 44px);
}

.bottle-outline {
    fill: #461e77;
}

.bottle-fluid {
    fill: #99db28;
    animation: clip 1.5s linear infinite;
    transform: translateY(-250px, 0px);
}

.loader {
    font-size: 10px;
    text-indent: -9999em;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: $violet;
    background: -moz-linear-gradient(left, $violet 10%, rgba(70, 30, 119, 0) 42%);
    background: -webkit-linear-gradient(left, $violet 10%, rgba(70, 30, 119, 0) 42%);
    background: -o-linear-gradient(left, $violet 10%, rgba(70, 30, 119, 0) 42%);
    background: -ms-linear-gradient(left, $violet 10%, rgba(70, 30, 119, 0) 42%);
    background: linear-gradient(to right, $violet 10%, rgba(70, 30, 119, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader:before {
    width: 50%;
    height: 50%;
    background: $violet;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader:after {
    background: $general-grey;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loader-container {
    background: $general-grey;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    @include flexbox;
    @include flexJustifyCenter;
    @include flexVcenter;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.round {
    position: absolute;
    border: 2px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    left: calc((100% - 40px) / 2);
    bottom: 10px;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;

    .arrow {
        position: absolute;
        bottom: 0;
        margin-left: 0px;
        width: 12px;
        height: 12px;
        background-size: contain;
        left: 12px;
        top: 15px;
        background: url('../images/for-all-page/white-arrow.svg') no-repeat 50% 50%;

        &:first-child {
            top: 7px;
            animation-name: jumpVertical;
            animation-duration: 1.5s;
            animation-delay: 0.1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        &:last-child {
            top: 17px;
            animation-name: jumpVertical;
            animation-duration: 1.5s;
            // animation-delay:0.2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}

.circle.-bottom {
    transform: rotate(180deg);
}

html {
    @media screen and (max-width: 1099px) {
        height: 62.89px;

        &.menu-active {
            //height: 100%;

            body {
                //position: fixed;
                width: 100%;
                top: 0px;
                overflow: hidden;
            }

            .header {
                height: 100%;
            }

            .header--bottom {
                display: block;
                margin-top: 0 !important;
                height: calc(100vh - 55px) !important;

                ul {
                    height: calc(100vh - 153px) !important;
                }
            }

            .lang-switch.d-block {
                ul {
                    @include flexbox;
                    @include flexDirectionRow;
                    height: auto !important;

                    li {
                        display: inline-block;
                        padding: 0 15px !important;
                        position: relative;
                        font-size: 16px !important;

                        &:first-child:after {
                            height: 16px !important;
                            width: 1px !important;
                            content: '';
                            background: #ffffff;
                            right: 0;
                            left: unset !important;
                            top: 0;
                            display: block;
                            position: absolute;

                            &:hover {
                                display: none;
                            }

                            // pointer-events: none;
                        }
                    }
                }
            }
        }

        .header {
            height: 87.89px;
            overflow: hidden;
        }
    }
}

body {
    font-family: 'roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'MHeiHK-Light', 'MHeiHK-Medium',
        'Microsoft JhengHei', '微軟正黑體!important';
    color: $font-grey;
    background-color: $general-grey !important;
}

sup {
    vertical-align: top;
    font-size: 12px;
    position: relative;
    top: -0.3em;
}

input {
    & ~ label {
        cursor: pointer;
    }
}

img {
    width: 100%;
    -ms-interpolation-mode: bicubic;
    // image-rendering: pixelated;
}

.inner-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

#root {
    max-width: 1440px;
    margin: auto;
}

#app-main {
    @include fontGeneralS;

    &.lang-en {
        @include fontGeneralS_EN;
    }
}

.row {
    @include flexbox;
    @include flexDirectionRow;
}

.col {
    @include flexbox;
    @include flexDirectionColumn;
}

.list-title {
    letter-spacing: 2px;
    font-size: 32px !important;
}

.svg-arrow {
    width: 7px;
    height: 11px;
    @include rotateArrow(-90deg, 0.3s);
    margin-left: 9px;

    &.upside-down {
        @include rotateArrow(90deg, 0.3s);
    }

    &.left {
        @include rotateArrow(180deg, 0.3s);
    }
}

.bullet-list {
    list-style: disc;
    padding-left: 20px;
}

.z-999 {
    z-index: 999;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.overflow-hidden {
    overflow: hidden;
}

.purple-arrow {
    position: relative;
    height: 10px !important;
    margin-left: 2px;
    width: 7px !important;
    vertical-align: middle;
}

.cursor-pointer {
    cursor: pointer;
}

.top-bar {
    height: 34px;
    background: $violet;
    text-align: center;
    padding-top: 4px;

    .shopping-cart {
        background: url('../images/for-all-page/shop-icon-m.png') 0 0 no-repeat;
        background-size: contain;
        height: 15px;
        width: 18px;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        margin-top: 4px;
    }

    .arrow {
        width: 12px;
        height: 12px;
        background-size: contain;
        background: url(/static/media/white-arrow.8b4adc4f.svg) no-repeat 50% 50%;
        display: inline-block;
        transform: rotate(-90deg);
        margin-right: 10px;
    }

    @media screen and (max-width: 1099px) {
        height: 32px;
        border-bottom: 5px solid $lightGreen;
        font-size: 12px;
        padding-top: 5px;

        .shopping-cart {
            height: 14px;
            width: 14px;
            margin-top: 2px;
        }

        .arrow {
            width: 11px;
            height: 9px;
        }
    }
}

.green-tag {
    background-image: url('../images/for-all-page/green-tag.png');
    background-size: cover;
    background-position: right;
    padding: 10px 15px;
    font-size: 45px;
    font-weight: bolder;
}

.violet-tag {
    @include fontGeneralL;
    background-image: url('../images/for-all-page/violet-tag.png');
    background-size: cover;
    background-position: right top;
    font-weight: bolder;
    padding: 10px 15px;
    position: relative;
    z-index: 1;
    letter-spacing: 2px !important;
    display: inline-block;

    &.lang-en {
        @include fontGeneralL_EN;
        letter-spacing: normal !important;
    }
}

.white-tag {
    @include fontGeneralL;
    background-image: url('../images/about/white-tag.png');
    background-size: cover;
    background-position: right top;
    font-weight: bolder;
    padding: 10px 15px;
    position: relative;
    z-index: 1;
    letter-spacing: 2px !important;
    width: fit-content;

    &.lang-en {
        @include fontGeneralL_EN;
        letter-spacing: normal !important;
    }
}

.line-height-20 {
    line-height: 20px;
}

.line-height-25 {
    line-height: 25px;
}

.ref-notes {
    max-height: 0;
    overflow: hidden;
    letter-spacing: normal;
    @include slideEffect(max-height, 0.3s, linear);

    &.active {
        max-height: 500px;
        @include slideEffect(max-height, 0.3s, linear);
    }
}

//===================== Header
.header {
    color: $white;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    line-height: initial;

    @media screen and (max-width: 1099px) {
        position: fixed;
        height: 100%;
        width: 100%;
        overflow: auto;

        a,
        .lang-switch,
        li {
            &.d-none {
                display: none !important;
            }

            &.d-block {
                display: block !important;
            }
        }
    }

    > * {
        letter-spacing: normal !important;
    }

    &--top {
        background: $lightGreen;
        z-index: 999;
        position: relative;

        .logo {
            display: none;

            @media screen and (max-width: 1099px) {
                img {
                    width: 100px;
                }
            }
        }

        .inner-container {
            li {
                &.active a {
                    color: $violet !important;

                    &::after {
                        display: none;
                    }
                }
            }

            @media screen and (min-width: 1100px) {
                @include flexbox;
            }
        }

        @media screen and (max-width: 1099px) {
            background: $violet;

            .menu-btn {
                @include flexbox;
                @include flexSpaceBetween;
                @include flexDirectionColumn;
                padding: 6px 0;
                cursor: pointer;

                .line {
                    border: 1px solid white;
                    width: 38px;
                    transition: transform 0.5s;
                    transition-delay: visibility 0.5s;
                    pointer-events: none;
                }

                &.active {
                    .line {
                        &:first-child {
                            transform: rotate(45deg);
                            transform-origin: center left;
                        }

                        &:nth-child(2) {
                            visibility: hidden;
                        }

                        &:last-child {
                            transform: rotate(-45deg);
                            transform-origin: center left;
                            position: relative;
                            bottom: -5px;
                        }
                    }
                }
            }
        }
    }

    &--bottom {
        background: $violet;
        height: 87px;

        @media screen and (max-width: 1099px) {
            background: rgba(153, 219, 40, 0.95);
            margin-top: calc(-100vh + 100px);

            &.active {
                display: block;
                margin-top: 0 !important;
                height: calc(100vh - 55px) !important;
            }

            ul {
                // transition: height 0.5s;
                height: 0 !important;

                &.active {
                    height: calc(100vh - 60px - 20px - 90px) !important;

                    &.is-ios {
                        height: calc(100vh - 60px - 110px) !important;

                        .pt-90 {
                            padding-top: 50px !important;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 320px) {
            ul {
                &.active {
                    &.is-ios {
                        height: calc(100vh - 58px - 5px - 20px - 60px) !important;
                    }
                }
            }
        }

        .menu-seperator {
            width: 1px;
            height: 24px;
            background-color: white;
        }

        .logo {
            width: 170px;
            display: inline-block;
            position: relative;

            img {
                width: 100%;
            }
        }

        .nav-logo {
            padding: 0 !important;

            @media screen and (max-width: 1099px) {
                display: none;
            }
        }
    }

    a {
        color: $white;
    }

    .inner-container {
        // padding: 10px 30px;
        padding: 0 30px;
        @include flexVcenter;

        @media screen and (max-width: 1099px) {
            padding: 10px;
        }

        .lang-switch.active {
            pointer-events: none;
        }

        .lang-switch.d-none {
            ul {
                @include flexbox;
                @include flexDirectionRow;
                @include flexVcenter;
            }

            li {
                padding: 0 10px;

                &:first-child:after {
                    width: 1px;
                    height: 14px;
                    background-color: white;
                    display: inline-block !important;
                    content: '';
                    margin-left: 10px;
                    vertical-align: middle;
                }

                &:hover {
                    &:after {
                        width: 1px !important;
                        height: 14px !important;
                        position: unset !important;
                    }
                }
            }
        }

        ul {
            @media screen and (min-width: 1100px) {
                &.lang-en {
                    height: 87px;

                    li {
                        padding: 30px 14px;
                        font-size: 19px;

                        a:after {
                            bottom: -32px;
                        }
                    }
                }
            }
        }

        li {
            position: relative;
            padding: 30px 0px;

            @media screen and (max-width: 1099px) {
                padding: 10px 0;
                font-size: 23px;
            }

            a {
                position: relative;
                z-index: 3;
            }

            &.active a {
                color: $lightGreen;

                @media screen and (max-width: 1099px) {
                    color: $violet;
                }
            }

            &:hover {
                .sub-menu {
                    display: block;
                }
            }

            &:not(:nth-child(9)):nth-child(odd) {
                &:hover {
                    @media screen and (min-width: 1100px) {
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: calc(100% + 200px);
                            height: 107px;
                            // background-color: red;
                            top: 0;
                            z-index: 2;
                            left: -100px;

                            &:hover {
                                .sub-menu {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            &:not(:nth-child(9)):nth-child(even) {
                @media screen and (min-width: 1100px) {
                    padding: 0;
                }
            }

            &.active {
                & > a {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        background-color: $lightGreen;
                        bottom: -30px;
                        left: 0;
                        pointer-events: none;

                        @media screen and (max-width: 1099px) {
                            display: none;
                        }
                    }
                }
            }
        }

        .fb-logo {
            width: 30px;
            height: 30px;
            background-size: contain;
            background-image: url('../images/for-all-page/f_logo_RGB_Blue_58.png');
            right: 40px;
            top: 0;

            @media screen and (max-width: 1099px) {
                width: 38px;
                height: 38px;
                right: 169px;
                top: 10px;
                background-image: url('../images/for-all-page/f_logo_RGB-White_58.png');
            }
        }

        .free-trail-btn {
            position: absolute;
            right: 85px;
            top: 2px;
            padding: 2.5px 20px 2.5px 12px;
            background-color: #4a267d;
            border-radius: 6px;
            height: 26px;
            letter-spacing: 3px;

            &.en {
                padding-top: 4px;
                letter-spacing: 0.5px;
            }

            ~ span {
                position: absolute;
                width: 12px;
                height: 12px;
                background-size: contain;
                background: url(/static/media/white-arrow.8b4adc4f.svg) no-repeat 50% 50%;
                transform: rotate(-90deg);
                top: 9px;
                right: 89px;
                @media screen and (max-width: $midBreakPoint) {
                    display: none;
                }
            }

            @media screen and (max-width: 1099px) {
                display: none;
            }
        }
    }

    .breadcrumb {
        @include flexbox;
        position: relative;

        @media screen and (min-width: 1100px) {
            @include flexVcenter;
            height: 30px;
        }

        @media screen and (max-width: 1099px) {
            @include flexSpaceBetween;
        }

        a {
            color: $lightGreen;
            text-decoration: none;
        }

        .m-help-icon {
            width: 38px;
            height: 38px;
            background-size: contain;
            // background-position: center;
        }

        .m-phone {
            background-image: url('../images/for-all-page/phone-icon-m.png');
            position: absolute;
            right: 55px;
        }

        .m-shopping-cart {
            background-image: url('../images/for-all-page/shop-icon-m.png');
            position: absolute;
            right: 110px;
            top: 3px;
        }
    }

    nav {
        display: block;
        // padding: 10px 0;
        max-width: 1050px;
        margin: auto;

        ul {
            @include flexbox;
            @include flexSpaceBetween;
            @include flexVcenter;

            @media screen and (max-width: 1099px) {
                @include flexDirectionColumn;
                @include flexSpaceAround;
                @include flexWrap;
                height: calc(100vh - 101px);
            }

            li {
                font-size: 20px;
                position: relative;

                a {
                    text-decoration: none;
                }
            }
        }
    }

    .menu-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: $white transparent transparent transparent;
        position: relative;
        top: 14px;
        left: 10px;
        vertical-align: top;
        display: inline-block;

        @media screen and (max-width: 1099px) {
            display: none;
        }

        &.active {
            border-color: #99db28 transparent transparent transparent;
        }
    }
}

//===================== Sub Menu
.submenu-container {
    // max-width: 1200px;
    left: -30px;
    top: 87px;
    width: calc(100% + 60px) !important;
}

.sub-menu {
    border: 2px solid #461e77;
    background-color: rgba(255, 255, 255, 0.9);
    display: none;
    font-size: 20px;
    border-radius: 5px;

    @media screen and (max-width: 1099px) {
        display: none !important;
    }

    &:hover {
        display: block;
    }

    .link {
        width: 100%;
        text-align: center;
        padding: 2px 0;
        padding: 15px 0;
        position: relative;

        @media screen and (max-width: 1099px) {
            font-size: 13px;
        }

        &:not(:last-of-type)::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(100%);
            height: 2px;
            background-color: $violet;
        }

        &:hover {
            background-color: #461e77;

            a {
                color: white !important;
            }
        }
    }

    a {
        display: block;
    }
}

//===================== Banner
.banner {
    height: auto;
    margin-top: calc(30px + 86px);
    font-size: 0;
    position: relative;

    @media screen and (max-width: $midBreakPoint) {
        height: auto;
        margin-top: 55px;
    }

    //NOTE: General cover fit banner background
    .cover-fit-bg {
        background-size: cover;
        height: calc(100vh - 121px);

        @media screen and (max-width: $midBreakPoint) {
            height: 0;
            padding-top: 51%;
            min-height: 347px;
        }
    }

    &--home {
        .cover-fit-bg {
            background-image: url('../images/homepage/home_banner.jpg');
            background-position: center 20%;
        }
    }

    &--degenerative-brain {
        .cover-fit-bg {
            background-image: url('../images/degenerativeBrain/top_banner.jpg');
            background-position: 80% center;
        }
    }

    &--brain-care {
        .cover-fit-bg {
            background-image: url('../images/brainCare/top_banner.jpg');
            background-position: 80% center;
        }
    }

    &--stores {
        .cover-fit-bg {
            background-image: url('../images/store/top_banner.jpg');
            background-position: center 20%;
            height: calc(100vh - 107px - 35px);

            @media screen and (max-width: $midBreakPoint) {
                height: 0 !important;
                padding-top: 51%;
            }
        }
    }

    &--page-about {
        .cover-fit-bg {
            height: calc(100vh - 107px - 35px);
            background-image: url('../images/about/souvenaid-about-top-banner-desktop_2023.jpg');
            background-position: center;
            width: 100%;

            @media screen and (max-width: $midBreakPoint) {
                height: 0;
                padding-top: 51%;
            }
        }

        img {
            height: auto !important;
            width: 100%;
        }
        .banner-image-en {
            @media screen and (max-width: $maxMobileWidth) {
                background-image: url('../images/about/souvenaid-about-top-banner-mb_2023.jpg');
                background-position: center;
                background-size: calc(100% + 5px);
                width: 100%;
            }
            img {
                @media screen and (max-width: $maxMobileWidth) {
                    opacity: 0;
                }
            }
        }
        .banner-image-tc {
            @media screen and (max-width: $maxMobileWidth) {
                background-image: url('../images/about/souvenaid-about-top-banner-mb_2023.jpg');
                background-position: center;
                background-size: calc(100% + 5px);
                width: 100%;
            }
            img {
                @media screen and (max-width: $maxMobileWidth) {
                    opacity: 0;
                }
            }
        }
    }

    &.lang-en {
        max-width: 65%;
    }

    @media screen and (min-width: 1024px) {
        .banner-image {
            &.is-IE {
                background-repeat: none;
                height: calc(100vh - 121px);
            }
        }

        img {
            &.is-IE {
                display: none;
            }
        }

        &--home {
            .banner-image {
                &.is-IE {
                    background-image: url('../images/homepage/home_banner.jpg');
                    background-position: center 20%;
                }
            }
        }

        &--degenerative-brain {
            .banner-image {
                &.is-IE {
                    background-image: url('../images/degenerativeBrain/top_banner.jpg');
                    background-position: 80% center;
                }
            }
        }

        &--brain-care {
            .banner-image {
                &.is-IE {
                    background-image: url('../images/brainCare/top_banner.jpg');
                    background-position: 80% center;
                }
            }
        }

        &--stores {
            .banner-image {
                &.is-IE {
                    background-image: url('../images/store/top_banner.jpg');
                    background-position: center 20%;
                }
            }
        }
    }

    &.lang-en {
        max-width: 65%;
    }

    img {
        object-fit: cover;
        display: block;

        @media screen and (min-width: 1100px) {
            height: calc(100vh - 107px - 35px);
        }
    }

    &--home {
        @media screen and (min-width: 1024px) {
            img {
                max-height: unset;
                height: calc(100vh - 121px);
            }
        }

        @media screen and (min-width: 1024px) {
            .home-banner-image {
                &.is-IE {
                    background-image: url('../images/homepage/home_banner.jpg');
                    background-position: center;
                    background-repeat: none;
                    height: calc(100vh - 121px);
                }
            }

            img {
                &.is-IE {
                    display: none;
                }
            }
        }

        .banner-title {
            position: absolute;
            color: white;
            text-align: center;
            font-weight: bolder;
            letter-spacing: 3.5px;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            width: 90%;
            @include flexJustifyCenter;
            @include flexDirectionColumn;

            @media screen and (max-width: 767px) {
                font-size: 32px;
            }
        }
    }

    &--degenerative-brain {
        .banner-title {
            height: 100%;
            position: absolute;
            top: 0;
        }
    }

    &--brain-care {
        .banner-title {
            height: 100%;
            position: absolute;
            top: 0;
        }
    }

    &--stores {
        .banner-title {
            height: calc(100% - 100px);
            position: absolute;
            top: 0;

            @media screen and (min-width: 1200px), (max-width: 1099px) {
                height: 100%;
            }

            @media screen and (max-width: 1230px) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
}

//===================== Bottom Nav
.bottom-nav {
    .row {
        @include flexSpaceBetween;
        @include flexVcenter;
    }

    .bottom-nav-item {
        @media screen and (max-width: $maxMobileWidth) {
            a {
                font-size: 20px !important;
                letter-spacing: normal;
            }

            img {
                width: 150px !important;
                max-width: 200px !important;
            }
        }

        img {
            width: 80%;
            margin: auto;
            max-width: 200px !important;
        }
    }
}

//===================== 404 Not Found
#error_404 {
    margin-top: calc(35px + 87px);
    min-height: calc(100vh - (35px + 87px) - 44px);

    @media screen and (max-width: $midBreakPoint) {
        height: 100%;
        margin-top: 60px;
        padding: 0 20px;
        min-height: calc(100vh - 60px - 44px);
    }

    @media screen and (max-width: $maxMobileWidth) {
        min-height: calc(100vh - 60px - 83px);
    }

    .inner-container {
        height: 100%;
        @include flexbox;
        @include flexVcenter;

        h3 {
            text-align: center;
            width: 100%;
            font-size: 2rem;
            line-height: 1.4;

            @media screen and (max-width: $midBreakPoint) {
                font-size: 1.5rem;
            }
        }

        a {
            color: $violet;
        }
    }
}

//===================== Footer
.footer {
    width: 100%;
    background: $lightGrey;

    &__desc {
        font-size: 20px;
        width: 100%;
        text-align: center;
        display: inline-block;
        margin: 15px 0;

        @media screen and (max-width: $maxMobileWidth) {
            font-size: 16px;
            padding: 10px;
            line-height: normal;
        }
    }

    &--bottom {
        padding: 15px 10px;
        @include flexbox;
        @include flexSpaceBetween;

        @media screen and (max-width: $maxMobileWidth) {
            @include flexDirectionColumn;
            padding: 0;
        }
    }

    &__menu {
        ul {
            width: 100%;
            @include flexbox;
            @include flexSpaceEvenly;

            @media screen and (max-width: $maxMobileWidth) {
                padding: 10px 30px;
            }

            li {
                position: relative;
                padding-right: 10px;
                color: $black;
                font-size: 14px;

                &:nth-child(even) {
                    display: none;
                }

                &:after {
                    margin-left: 10px;
                    content: '>';
                }

                @media screen and (max-width: $maxMobileWidth) {
                    &:nth-child(even) {
                        display: block;
                    }

                    text-align: center;
                    padding: 0;
                    font-size: 9px;

                    &:nth-child(even):after {
                        margin-left: 0;
                        content: '|';
                        position: absolute;
                        right: 0;
                    }

                    &:nth-child(odd):after {
                        display: none;
                    }
                }

                a {
                    text-decoration: none;
                    color: $black;
                }
            }
        }
    }

    .copyright {
        font-size: 12px;

        @media screen and (max-width: $maxMobileWidth) {
            padding: 20px 10px;
            font-size: 14px;
            text-align: center;
            background-color: #858585;
            color: $white;
        }
    }
}

//===================== fixed help box
.help-conainer {
    position: fixed;
    bottom: 15px;
    right: 10px;
    z-index: 2;
    font-size: 12px;
    line-height: normal;

    > * {
        letter-spacing: normal;
    }

    @media all and (min-width: 1440px) {
        right: 50%;
        margin-right: calc(-720px + 20px);
    }

    @media screen and (max-width: $maxMobileWidth) {
        display: none;
    }

    @keyframes jump {
        0% {
            transform: translateY(0);
        }

        25% {
            transform: translateY(2px);
        }

        75% {
            transform: translateY(-2px);
        }

        100% {
            transform: translateY(0);
        }
    }

    .fb-page-btn {
        width: 75px;
        height: 75px;
        //background-image: url('../images/for-all-page/fb-iframe-icon.svg');
        background-size: contain;
        margin-right: 15px;
        border-radius: 50%;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
            0px 1px 18px 0px rgba(0, 0, 0, 0.12);

        &:hover {
            .fb-iframe {
                display: block;
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 30px;
            background: transparent;
            left: -12px;
            top: 0;

            .fb-iframe {
                display: block;
            }
        }

        span {
            left: 36%;
            top: 50px;

            &.lang-en {
                left: 12px;
            }
        }
    }

    .button-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
        }
    }

    .shopping-cart {
        width: 75px;
        height: 75px;
        //background-image: url('../images/for-all-page/shop_icon.svg');
        background-size: contain;
        margin-bottom: 10px;
        // display: inline-block;
        margin-right: 15px;
        border-radius: 50%;
        animation: jump 1s linear infinite;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
            0px 1px 18px 0px rgba(0, 0, 0, 0.12);

        span {
            &.lang-en {
                left: 21px;
            }
        }
    }

    .call-us {
        width: 75px;
        height: 75px;
        position: relative;
        //background-image: url('../images/for-all-page/phone_icon.svg');
        background-size: contain;
        margin-bottom: 10px;
        // display: inline-block;
        border-radius: 50%;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
            0px 1px 18px 0px rgba(0, 0, 0, 0.12);

        span {
            &.lang-en {
                left: 21px;
            }
        }
    }

    span {
        top: 47px;
        position: relative;
        z-index: 999;
        bottom: -11px;
        left: 19%;
    }
}

//===================== Facebook
.fb-iframe {
    position: absolute;
    display: none;
    bottom: 49px;
    left: -476px;

    &.isIE {
        left: -354px;
    }

    &:hover {
        display: block;
    }

    @media all and (min-width: 1920px) {
        // display: block;
    }
}

.show-on-320 {
    display: none;
}

@media (max-width: 320px) {
    .show-on-320 {
        display: inline-block;
    }
}

//======================================
//============ For Webp ================
.webp .banner--stores .cover-fit-bg {
    background-image: url('../images/store/top_banner.webp');
    background-position: center 20%;
    height: calc(100vh - 107px - 35px);
}

.webp .banner--home .cover-fit-bg {
    background-image: url('../images/homepage/home_banner.webp');
    background-position: center 20%;
}

.webp .banner--degenerative-brain .cover-fit-bg {
    background-image: url('../images/degenerativeBrain/top_banner.webp');
    background-position: 80% center;
}

.webp .banner--brain-care .cover-fit-bg {
    background-image: url('../images/brainCare/top_banner.webp');
    background-position: 80% center;
}
.video-container {
    width: 100%;
    position: relative;
}
.video-react {
    width: 100%;
    height: 0;
    position: relative;
    margin: 40px auto;
}
video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.play-button {
    position: absolute;
    background: none;
    outline: none;
    border: none;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    &[data-playing='true'] {
        display: none;
    }
    img {
        width: 100px;
        height: 100px;
    }
}
// .video-react-control-bar,
// .video-react-big-play-button {
//     display: none;
// }

.video-react .video-react-big-play-button {
    opacity: 0 !important;
}
.video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    margin-top: 40px;
    margin-bottom: 40px;
}
.video-wrapper > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    height: 0;
}
.video-wrapper .video-react,
.video-wrapper .video-react:focus,
.video-wrapper .video-react video:focus {
    outline: none !important;
}
