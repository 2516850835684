@mixin homeBtnHeightAnimtions {
    transition: height 0.3s linear;
    -webkit-transition: height 0.3s linear;
    -moz-transition: height 0.3s linear;
    -ms-transition: height 0.3s linear;
    -o-transition: height 0.3s linear;
}

@mixin homeBtnColorAnimtions {
    transition: color 0.3s linear;
    -webkit-transition: color 0.3s linear;
    -moz-transition: color 0.3s linear;
    -ms-transition: color 0.3s linear;
    -o-transition: color 0.3s linear;
}

@mixin slideEffect($property, $durTime, $effect) {
    transition: $property $durTime $effect;
    -webkit-transition: $property $durTime $effect;
    -moz-transition: $property $durTime $effect;
    -ms-transition: $property $durTime $effect;
    -o-transition: $property $durTime $effect;
}

@mixin imgZoomIn($value) {
    -webkit-transform: scale($value);
    -ms-transform: scale($value);
    transform: scale($value);
    -moz-transform: scale($value);
    -o-transform: scale($value);
    transition: transform 0.3s linear;
    -webkit-transition: transform 0.3s linear;
    -moz-transition: transform 0.3s linear;
    -ms-transition: transform 0.3s linear;
    -o-transition: transform 0.3s linear;
}

@mixin imgZoomOut($value) {
    -webkit-transform: scale($value);
    -ms-transform: scale($value);
    transform: scale($value);
    -moz-transform: scale($value);
    -o-transform: scale($value);
    transition: transform 0.3s linear;
    -webkit-transition: transform 0.3s linear;
    -moz-transition: transform 0.3s linear;
    -ms-transition: transform 0.3s linear;
    -o-transition: transform 0.3s linear;
}

@mixin rotateArrow($rotateDeg, $durTime) {
    -webkit-transform: rotate($rotateDeg);
    -ms-transform: rotate($rotateDeg);
    transform: rotate($rotateDeg);

    transition: transform $durTime linear;
    -webkit-transition: transform $durTime linear;
    -moz-transition: transform $durTime linear;
    -ms-transition: transform $durTime linear;
    -o-transition: transform $durTime linear;
}

// @mixin animation($name) {
@keyframes jumpVertical {
    0% {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
        opacity: 1;
    }

    25% {
        -webkit-transform: translateY(10px) scale(0.9);
        -ms-transform: translateY(10px) scale(0.9);
        transform: translateY(10px) scale(0.9);
        opacity: 0;
    }

    26% {
        -webkit-transform: translateY(-10px) scale(0.9);
        -ms-transform: translateY(-10px) scale(0.9);
        transform: translateY(-10px) scale(0.9);
        opacity: 0;
    }

    55% {
        -webkit-transform: translateY(0) scale(1);
        -ms-transform: translateY(0) scale(1);
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

// }