@import './_variables.scss';

.mw-content {
    max-width: 1200px;
    margin: auto;

}

.max-view {
    max-width: 1920px;
    margin: auto;
}

.w-md-10 {
    width: 10% !important;
}


.w-md-20 {
    width: 20% !important;
}

.w-md-30 {
    width: 30% !important;
}

.w-md-35 {
    width: 35% !important;
}

.w-md-40 {
    width: 40% !important;
}

.w-md-50 {
    width: 50% !important;
}

.w-md-60 {
    width: 60% !important;
}

.w-md-64 {
    width: 64% !important;
}

.w-md-65 {
    width: 65% !important;
}

.w-md-70 {
    width: 70% !important;
}

.w-md-80 {
    width: 80% !important;
}

.w-md-85 {
    width: 85% !important;
}

.w-md-90 {
    width: 90% !important;
}

.w-md-100 {
    width: 100% !important;
}

.w-md-auto {
    width: auto !important;
}

.w-md-auto {
    width: auto !important;
}

.vw-md-100 {
    width: 100vw !important;
    // max-width: 1920px!important;
}

.w-md-para {
    width: calc(100% - 406px);
}

.min-w-md-480 {
    min-width: 480px !important;
}

.min-w-md-0 {
    min-width: 0px !important;
}

// height
.h-md-10 {
    height: 10% !important;
}

.h-md-20 {
    height: 20% !important;
}

.h-md-30 {
    height: 30% !important;
}

.h-md-40 {
    height: 40% !important;
}

.h-md-50 {
    height: 50% !important;
}

.h-md-60 {
    height: 60% !important;
}


.h-md-70 {
    height: 70% !important;
}

.h-md-80 {
    height: 80% !important;
}

.h-md-90 {
    height: 90% !important;
}

.h-md-100 {
    height: 100% !important;
}

.h-md-auto {
    height: auto !important;
}

.max-w-md-480 {
    max-width: 480px !important;
}

@media screen and (max-width: $maxMobileWidth) {
    .w-10 {
        width: 10% !important;
    }

    .w-20 {
        width: 20% !important;
    }

    .w-30 {
        width: 30% !important;
    }

    .w-40 {
        width: 40% !important;
    }

    .w-50 {
        width: 50% !important;
    }

    .w-60 {
        width: 60% !important;
    }


    .w-70 {
        width: 70% !important;
    }

    .w-80 {
        width: 80% !important;
    }

    .w-90 {
        width: 90% !important;
    }

    .w-100 {
        width: 100% !important;
    }

    .w-auto {
        width: auto !important;
    }

    .vw-100 {
        width: 100vw !important;
        max-width: 1920px !important;
    }

    .min-w-480 {
        min-width: 480px !important;
    }

    .min-w-0 {
        min-width: 0px !important;
    }

    .max-w-480 {
        max-width: 480px !important;
    }
}

@media screen and (min-width: 1367px) {
    .w-lg-10 {
        width: 10% !important;
    }

    .w-lg-20 {
        width: 20% !important;
    }

    .w-lg-30 {
        width: 30% !important;
    }

    .w-lg-35 {
        width: 35% !important;
    }

    .w-lg-40 {
        width: 40% !important;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-60 {
        width: 60% !important;
    }

    .w-lg-65 {
        width: 65% !important;
    }

    .w-lg-70 {
        width: 70% !important;
    }

    .w-lg-80 {
        width: 80% !important;
    }

    .w-lg-90 {
        width: 90% !important;
    }

    .w-lg-100 {
        width: 100% !important;
    }

    .w-lg-auto {
        width: auto !important;
    }

    .vw-lg-100 {
        width: 100vw !important;
        max-width: 1920px !important;
    }

    .min-w-lg-480 {
        min-width: 480px !important;
    }

    .min-w-lg-0 {
        min-width: 0px !important;
    }

    .max-w-lg-480 {
        max-width: 480px !important;
    }

    .max-w-lg-unset {
        max-width: unset !important
    }
}