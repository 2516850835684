$white: #ffffff;
$black: #000000;
$lightGrey: #F8F8F8;
$violet: #461E77;
$lightGreen: #99DB28;
$lightBlue: #EEFAFF;
$grey: #E4E4E4;
$yello: #F1C706;
$font-grey: #777777;
$font-black: #383838;
$light-violet: #5A13B0;
$general-grey: #d9d9d9;
$light-purple:#FBF7FF;

.light-green {
    color: $lightGreen!important;
}

.white {
    color: $white!important;
}

.violet {
    color: $violet!important;
}

.black {
    color: $black!important;
}

.yellow {
    color: $yello!important;
}

.font-grey {
  color: $font-grey!important;  
}

.font-black {
    color: $font-black!important;
}

.light-violet {
    color: $light-violet!important;
}
