.border {
    border: 1px solid $white !important;
}

.border-top {
    border-top: 1px solid $white !important;
}

.border-right {
    border-right: 1px solid $white !important;
}

.border-bottom {
    border-bottom: 1px solid $white !important;
}

.border-left {
    border-left: 1px solid $white !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-violet {
    border-color: $violet !important;
}

.border-white {
    border-color: $white !important;
}