@import './_variables.scss';

.text-md-justify {
  text-align: justify !important;
}

.text-md-wrap {
  white-space: normal !important;
}

.text-md-nowrap {
  white-space: nowrap !important;
}

.text-md-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-md-left {
  text-align: left !important;
}

.text-md-right {
  text-align: right !important;
}

.text-md-center {
  text-align: center !important;
}

.text-md-underline {
  text-decoration: underline;
}

.italic-underline {
  font-style: italic;
  text-decoration: underline;
}

@media screen and (max-width: $maxMobileWidth) {
  .text-justify {
    text-align: justify !important;
  }

  .text-wrap {
    white-space: normal !important;
  }

  .text-nowrap {
    white-space: nowrap !important;
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}