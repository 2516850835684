@import './_variables.scss';

.m-md-auto {
    margin: auto !important;
}

.mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
}

.mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
}

.mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
}

.ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
}

.m-md-0 {
    margin: 0 !important;
}

.mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
}

.mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
}

.mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
}

.ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
}

.m-md-5 {
    margin: 5px !important;
}

.mt-md-5,
.my-md-5 {
    margin-top: 5px !important;
}

.mr-md-5,
.mx-md-5 {
    margin-right: 5px !important;
}

.mb-md-5,
.my-md-5 {
    margin-bottom: 5px !important;
}

.ml-md-5,
.mx-md-5 {
    margin-left: 5px !important;
}

.m-md-10 {
    margin: 10px !important;
}

.mt-md-10,
.my-md-10 {
    margin-top: 10px !important;
}

.mr-md-10,
.mx-md-10 {
    margin-right: 10px !important;
}

.mb-md-10,
.my-md-10 {
    margin-bottom: 10px !important;
}

.ml-md-10,
.mx-md-10 {
    margin-left: 10px !important;
}

.m-md-15 {
    margin: 15px !important;
}

.mt-md-15,
.my-md-15 {
    margin-top: 15px !important;
}

.mr-md-15,
.mx-md-15 {
    margin-right: 15px !important;
}

.mb-md-15,
.my-md-15 {
    margin-bottom: 15px !important;
}

.ml-md-15,
.mx-md-15 {
    margin-left: 15px !important;
}

.m-md-20 {
    margin: 20px !important;
}

.mt-md-20,
.my-md-20 {
    margin-top: 20px !important;
}

.mr-md-20,
.mx-md-20 {
    margin-right: 20px !important;
}

.mb-md-20,
.my-md-20 {
    margin-bottom: 20px !important;
}

.ml-md-20,
.mx-md-20 {
    margin-left: 20px !important;
}

.m-md-25 {
    margin: 25px !important;
}

.mt-md-25,
.my-md-25 {
    margin-top: 25px !important;
}

.mr-md-25,
.mx-md-25 {
    margin-right: 25px !important;
}

.mb-md-25,
.my-md-25 {
    margin-bottom: 25px !important;
}

.ml-md-25,
.mx-md-25 {
    margin-left: 25px !important;
}

.m-md-30 {
    margin: 30px !important;
}

.mt-md-30,
.my-md-30 {
    margin-top: 30px !important;
}

.mr-md-30,
.mx-md-30 {
    margin-right: 30px !important;
}

.mb-md-30,
.my-md-30 {
    margin-bottom: 30px !important;
}

.ml-md-30,
.mx-md-30 {
    margin-left: 30px !important;
}

.m-md-35 {
    margin: 35px !important;
}

.mt-md-35,
.my-md-35 {
    margin-top: 35px !important;
}

.mr-md-35,
.mx-md-35 {
    margin-right: 35px !important;
}

.mb-md-35,
.my-md-35 {
    margin-bottom: 35px !important;
}

.ml-md-35,
.mx-md-35 {
    margin-left: 35px !important;
}

.m-md-40 {
    margin: 40px !important;
}

.mt-md-40,
.my-md-40 {
    margin-top: 40px !important;
}

.mr-md-40,
.mx-md-40 {
    margin-right: 40px !important;
}

.mb-md-40,
.my-md-40 {
    margin-bottom: 40px !important;
}

.ml-md-40,
.mx-md-40 {
    margin-left: 40px !important;
}

.m-md-45 {
    margin: 45px !important;
}

.mt-md-45,
.my-md-45 {
    margin-top: 45px !important;
}

.mr-md-45,
.mx-md-45 {
    margin-right: 45px !important;
}

.mb-md-45,
.my-md-45 {
    margin-bottom: 45px !important;
}

.ml-md-45,
.mx-md-45 {
    margin-left: 45px !important;
}

.m-md-50 {
    margin: 50px !important;
}

.mt-md-50,
.my-md-50 {
    margin-top: 50px !important;
}

.mr-md-50,
.mx-md-50 {
    margin-right: 50px !important;
}

.mb-md-50,
.my-md-50 {
    margin-bottom: 50px !important;
}

.ml-md-50,
.mx-md-50 {
    margin-left: 50px !important;
}

.m-md-100 {
    margin: 100px !important;
}

.mt-md-100,
.my-md-100 {
    margin-top: 100px !important;
}

.mr-md-100,
.mx-md-100 {
    margin-right: 100px !important;
}

.mb-md-100,
.my-md-100 {
    margin-bottom: 100px !important;
}

.ml-md-100,
.mx-md-100 {
    margin-left: 100px !important;
}

.m-md-150 {
    margin: 150px !important;
}

.mt-md-150,
.my-md-150 {
    margin-top: 150px !important;
}

.mr-md-150,
.mx-md-150 {
    margin-right: 150px !important;
}

.mb-md-150,
.my-md-150 {
    margin-bottom: 150px !important;
}

.ml-md-150,
.mx-md-150 {
    margin-left: 150px !important;
}

.p-md-0 {
    padding: 0 !important;
}

.pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
}

.pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
}

.pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
}

.pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
}

.p-md-5 {
    padding: 5px !important;
}

.pt-md-5,
.py-md-5 {
    padding-top: 5px !important;
}

.pr-md-5,
.px-md-5 {
    padding-right: 5px !important;
}

.pb-md-5,
.py-md-5 {
    padding-bottom: 5px !important;
}

.pl-md-5,
.px-md-5 {
    padding-left: 5px !important;
}

.p-md-10 {
    padding: 10px !important;
}

.pt-md-10,
.py-md-10 {
    padding-top: 10px !important;
}

.pr-md-10,
.px-md-10 {
    padding-right: 10px !important;
}

.pb-md-10,
.py-md-10 {
    padding-bottom: 10px !important;
}

.pl-md-10,
.px-md-10 {
    padding-left: 10px !important;
}

.p-md-15 {
    padding: 15px !important;
}

.pt-md-15,
.py-md-15 {
    padding-top: 15px !important;
}

.pr-md-15,
.px-md-15 {
    padding-right: 15px !important;
}

.pb-md-15,
.py-md-15 {
    padding-bottom: 15px !important;
}

.pl-md-15,
.px-md-15 {
    padding-left: 15px !important;
}

.p-md-20 {
    padding: 20px !important;
}

.pt-md-20,
.py-md-20 {
    padding-top: 20px !important;
}

.pr-md-20,
.px-md-20 {
    padding-right: 20px !important;
}

.pb-md-20,
.py-md-20 {
    padding-bottom: 20px !important;
}

.pl-md-20,
.px-md-20 {
    padding-left: 20px !important;
}

.p-md-25 {
    padding: 25px !important;
}

.pt-md-25,
.py-md-25 {
    padding-top: 25px !important;
}

.pr-md-25,
.px-md-25 {
    padding-right: 25px !important;
}

.pb-md-25,
.py-md-25 {
    padding-bottom: 25px !important;
}

.pl-md-25,
.px-md-25 {
    padding-left: 25px !important;
}

.p-md-30 {
    padding: 30px !important;
}

.pt-md-30,
.py-md-30 {
    padding-top: 30px !important;
}

.pr-md-30,
.px-md-30 {
    padding-right: 30px !important;
}

.pb-md-30,
.py-md-30 {
    padding-bottom: 30px !important;
}

.pl-md-30,
.px-md-30 {
    padding-left: 30px !important;
}

.p-md-35 {
    padding: 35px !important;
}

.pt-md-35,
.py-md-35 {
    padding-top: 35px !important;
}

.pr-md-35,
.px-md-35 {
    padding-right: 35px !important;
}

.pb-md-35,
.py-md-35 {
    padding-bottom: 35px !important;
}

.pl-md-35,
.px-md-35 {
    padding-left: 35px !important;
}

.p-md-40 {
    padding: 40px !important;
}

.pt-md-40,
.py-md-40 {
    padding-top: 40px !important;
}

.pr-md-40,
.px-md-40 {
    padding-right: 40px !important;
}

.pb-md-40,
.py-md-40 {
    padding-bottom: 40px !important;
}

.pl-md-40,
.px-md-40 {
    padding-left: 40px !important;
}

.p-md-45 {
    padding: 45px !important;
}

.pt-md-45,
.py-md-45 {
    padding-top: 45px !important;
}

.pr-md-45,
.px-md-45 {
    padding-right: 45px !important;
}

.pb-md-45,
.py-md-45 {
    padding-bottom: 45px !important;
}

.pl-md-45,
.px-md-45 {
    padding-left: 45px !important;
}

.p-md-50 {
    padding: 50px !important;
}

.pt-md-50,
.py-md-50 {
    padding-top: 50px !important;
}

.pr-md-50,
.px-md-50 {
    padding-right: 50px !important;
}

.pt-md-60,
.py-md-60 {
    padding-top: 60px !important;
}

.pr-md-70,
.px-md-70 {
    padding-right: 50px !important;
}

.pt-md-70,
.py-md-70 {
    padding-top: 50px !important;
}

.pr-md-70,
.px-md-70 {
    padding-right: 50px !important;
}

.pt-md-80,
.py-md-80 {
    padding-top: 80px !important;
}

.pr-md-80,
.px-md-80 {
    padding-right: 80px !important;
}

.pt-md-90,
.py-md-90 {
    padding-top: 90px !important;
}

.pr-md-90,
.px-md-90 {
    padding-right: 900px !important;
}

.pb-md-50,
.py-md-50 {
    padding-bottom: 50px !important;
}

.pb-md-60,
.py-md-60 {
    padding-bottom: 60px !important;
}

.pb-md-70,
.py-md-70 {
    padding-bottom: 70px !important;
}

.pb-md-80,
.py-md-80 {
    padding-bottom: 80px !important;
}

.pb-md-90,
.py-md-90 {
    padding-bottom: 90px !important;
}

.pl-md-60,
.px-md-60 {
    padding-left: 60px !important;
}

.pl-md-70,
.px-md-70 {
    padding-left: 70px !important;
}

.pl-md-80,
.px-md-80 {
    padding-left: 80px !important;
}

.pl-md-90,
.px-md-90 {
    padding-left: 90px !important;
}

.p-md-100 {
    padding: 100px !important;
}

.pt-md-100,
.py-md-100 {
    padding-top: 100px !important;
}

.pr-md-100,
.px-md-100 {
    padding-right: 100px !important;
}

.pb-md-100,
.py-md-100 {
    padding-bottom: 100px !important;
}

.pl-md-100,
.px-md-100 {
    padding-left: 100px !important;
}

.p-md-150 {
    padding: 150px !important;
}

.pt-md-150,
.py-md-150 {
    padding-top: 150px !important;
}

.pr-md-150,
.px-md-150 {
    padding-right: 150px !important;
}

.pb-md-150,
.py-md-150 {
    padding-bottom: 150px !important;
}

.pl-md-150,
.px-md-150 {
    padding-left: 150px !important;
}

@media screen and (max-width: $maxMobileWidth) {
    .p-0 {
        padding: 0 !important;
    }

    .pt-0,
    .py-0 {
        padding-top: 0 !important;
    }

    .pr-0,
    .px-0 {
        padding-right: 0 !important;
    }

    .pb-0,
    .py-0 {
        padding-bottom: 0 !important;
    }

    .pl-0,
    .px-0 {
        padding-left: 0 !important;
    }

    .p-5 {
        padding: 5px !important;
    }

    .pt-5,
    .py-5 {
        padding-top: 5px !important;
    }

    .pr-5,
    .px-5 {
        padding-right: 5px !important;
    }

    .pb-5,
    .py-5 {
        padding-bottom: 5px !important;
    }

    .pl-5,
    .px-5 {
        padding-left: 5px !important;
    }

    .p-10 {
        padding: 10px !important;
    }

    .pt-10,
    .py-10 {
        padding-top: 10px !important;
    }

    .pr-10,
    .px-10 {
        padding-right: 10px !important;
    }

    .pb-10,
    .py-10 {
        padding-bottom: 10px !important;
    }

    .pl-10,
    .px-10 {
        padding-left: 10px !important;
    }

    .p-15 {
        padding: 15px !important;
    }

    .pt-15,
    .py-15 {
        padding-top: 15px !important;
    }

    .pr-15,
    .px-15 {
        padding-right: 15px !important;
    }

    .pb-15,
    .py-15 {
        padding-bottom: 15px !important;
    }

    .pl-15,
    .px-15 {
        padding-left: 15px !important;
    }

    .p-20 {
        padding: 20px !important;
    }

    .pt-20,
    .py-20 {
        padding-top: 20px !important;
    }

    .pr-20,
    .px-20 {
        padding-right: 20px !important;
    }

    .pb-20,
    .py-20 {
        padding-bottom: 20px !important;
    }

    .pl-20,
    .px-20 {
        padding-left: 20px !important;
    }

    .p-25 {
        padding: 25px !important;
    }

    .pt-25,
    .py-25 {
        padding-top: 25px !important;
    }

    .pr-25,
    .px-25 {
        padding-right: 25px !important;
    }

    .pb-25,
    .py-25 {
        padding-bottom: 25px !important;
    }

    .pl-25,
    .px-25 {
        padding-left: 25px !important;
    }

    .p-30 {
        padding: 30px !important;
    }

    .pt-30,
    .py-30 {
        padding-top: 30px !important;
    }

    .pr-30,
    .px-30 {
        padding-right: 30px !important;
    }

    .pb-30,
    .py-30 {
        padding-bottom: 30px !important;
    }

    .pl-30,
    .px-30 {
        padding-left: 30px !important;
    }

    .p-35 {
        padding: 35px !important;
    }

    .pt-35,
    .py-35 {
        padding-top: 35px !important;
    }

    .pr-35,
    .px-35 {
        padding-right: 35px !important;
    }

    .pb-35,
    .py-35 {
        padding-bottom: 35px !important;
    }

    .pl-35,
    .px-35 {
        padding-left: 35px !important;
    }

    .p-40 {
        padding: 40px !important;
    }

    .pt-40,
    .py-40 {
        padding-top: 40px !important;
    }

    .pr-40,
    .px-40 {
        padding-right: 40px !important;
    }

    .pb-40,
    .py-40 {
        padding-bottom: 40px !important;
    }

    .pl-40,
    .px-40 {
        padding-left: 40px !important;
    }

    .p-45 {
        padding: 45px !important;
    }

    .pt-45,
    .py-45 {
        padding-top: 45px !important;
    }

    .pr-45,
    .px-45 {
        padding-right: 45px !important;
    }

    .pb-45,
    .py-45 {
        padding-bottom: 45px !important;
    }

    .pl-45,
    .px-45 {
        padding-left: 45px !important;
    }

    .p-50 {
        padding: 50px !important;
    }

    .pt-50,
    .py-50 {
        padding-top: 50px !important;
    }

    .pr-50,
    .px-50 {
        padding-right: 50px !important;
    }

    .pb-50,
    .py-50 {
        padding-bottom: 50px !important;
    }

    .pl-50,
    .px-50 {
        padding-left: 50px !important;
    }

    .p-90 {
        padding: 90px !important;
    }

    .pt-90,
    .py-90 {
        padding-top: 90px !important;
    }

    .pr-90,
    .px-90 {
        padding-right: 90px !important;
    }

    .pb-90,
    .py-90 {
        padding-bottom: 90px !important;
    }

    .pl-90,
    .px-90 {
        padding-left: 90px !important;
    }

    .p-100 {
        padding: 100px !important;
    }

    .pt-100,
    .py-100 {
        padding-top: 100px !important;
    }

    .pr-100,
    .px-100 {
        padding-right: 100px !important;
    }

    .pb-100,
    .py-100 {
        padding-bottom: 100px !important;
    }

    .pl-100,
    .px-100 {
        padding-left: 100px !important;
    }

    .p-150 {
        padding: 150px !important;
    }

    .pt-150,
    .py-150 {
        padding-top: 150px !important;
    }

    .pr-150,
    .px-150 {
        padding-right: 150px !important;
    }

    .pb-150,
    .py-150 {
        padding-bottom: 150px !important;
    }

    .pl-150,
    .px-150 {
        padding-left: 150px !important;
    }

    // margin
    .m-auto {
        margin: auto !important;
    }

    .mt-auto,
    .my-auto {
        margin-top: auto !important;
    }

    .mr-auto,
    .mx-auto {
        margin-right: auto !important;
    }

    .mb-auto,
    .my-auto {
        margin-bottom: auto !important;
    }

    .ml-auto,
    .mx-auto {
        margin-left: auto !important;
    }

    .m-0 {
        margin: 0 !important;
    }

    .mt-0,
    .my-0 {
        margin-top: 0 !important;
    }

    .mr-0,
    .mx-0 {
        margin-right: 0 !important;
    }

    .mb-0,
    .my-0 {
        margin-bottom: 0 !important;
    }

    .ml-0,
    .mx-0 {
        margin-left: 0 !important;
    }

    .m-5 {
        margin: 5px !important;
    }

    .mt-5,
    .my-5 {
        margin-top: 5px !important;
    }

    .mr-5,
    .mx-5 {
        margin-right: 5px !important;
    }

    .mb-5,
    .my-5 {
        margin-bottom: 5px !important;
    }

    .ml-5,
    .mx-5 {
        margin-left: 5px !important;
    }

    .m-10 {
        margin: 10px !important;
    }

    .mt-10,
    .my-10 {
        margin-top: 10px !important;
    }

    .mr-10,
    .mx-10 {
        margin-right: 10px !important;
    }

    .mb-10,
    .my-10 {
        margin-bottom: 10px !important;
    }

    .ml-10,
    .mx-10 {
        margin-left: 10px !important;
    }

    .m-15 {
        margin: 15px !important;
    }

    .mt-15,
    .my-15 {
        margin-top: 15px !important;
    }

    .mr-15,
    .mx-15 {
        margin-right: 15px !important;
    }

    .mb-15,
    .my-15 {
        margin-bottom: 15px !important;
    }

    .ml-15,
    .mx-15 {
        margin-left: 15px !important;
    }

    .m-20 {
        margin: 20px !important;
    }

    .mt-20,
    .my-20 {
        margin-top: 20px !important;
    }

    .mr-20,
    .mx-20 {
        margin-right: 20px !important;
    }

    .mb-20,
    .my-20 {
        margin-bottom: 20px !important;
    }

    .ml-20,
    .mx-20 {
        margin-left: 20px !important;
    }

    .m-25 {
        margin: 25px !important;
    }

    .mt-25,
    .my-25 {
        margin-top: 25px !important;
    }

    .mr-25,
    .mx-25 {
        margin-right: 25px !important;
    }

    .mb-25,
    .my-25 {
        margin-bottom: 25px !important;
    }

    .ml-25,
    .mx-25 {
        margin-left: 25px !important;
    }

    .m-30 {
        margin: 30px !important;
    }

    .mt-30,
    .my-30 {
        margin-top: 30px !important;
    }

    .mr-30,
    .mx-30 {
        margin-right: 30px !important;
    }

    .mb-30,
    .my-30 {
        margin-bottom: 30px !important;
    }

    .ml-30,
    .mx-30 {
        margin-left: 30px !important;
    }

    .m-35 {
        margin: 35px !important;
    }

    .mt-35,
    .my-35 {
        margin-top: 35px !important;
    }

    .mr-35,
    .mx-35 {
        margin-right: 35px !important;
    }

    .mb-35,
    .my-35 {
        margin-bottom: 35px !important;
    }

    .ml-35,
    .mx-35 {
        margin-left: 35px !important;
    }

    .m-40 {
        margin: 40px !important;
    }

    .mt-40,
    .my-40 {
        margin-top: 40px !important;
    }

    .mr-40,
    .mx-40 {
        margin-right: 40px !important;
    }

    .mb-40,
    .my-40 {
        margin-bottom: 40px !important;
    }

    .ml-40,
    .mx-40 {
        margin-left: 40px !important;
    }

    .m-45 {
        margin: 45px !important;
    }

    .mt-45,
    .my-45 {
        margin-top: 45px !important;
    }

    .mr-45,
    .mx-45 {
        margin-right: 45px !important;
    }

    .mb-45,
    .my-45 {
        margin-bottom: 45px !important;
    }

    .ml-45,
    .mx-45 {
        margin-left: 45px !important;
    }

    .m-50 {
        margin: 50px !important;
    }

    .mt-50,
    .my-50 {
        margin-top: 50px !important;
    }

    .mr-50,
    .mx-50 {
        margin-right: 50px !important;
    }

    .mb-50,
    .my-50 {
        margin-bottom: 50px !important;
    }

    .ml-50,
    .mx-50 {
        margin-left: 50px !important;
    }

    .m-100 {
        margin: 100px !important;
    }

    .mt-100,
    .my-100 {
        margin-top: 100px !important;
    }

    .mr-100,
    .mx-100 {
        margin-right: 100px !important;
    }

    .mb-100,
    .my-100 {
        margin-bottom: 100px !important;
    }

    .ml-100,
    .mx-100 {
        margin-left: 100px !important;
    }

    .m-150 {
        margin: 150px !important;
    }

    .mt-150,
    .my-150 {
        margin-top: 150px !important;
    }

    .mr-150,
    .mx-150 {
        margin-right: 150px !important;
    }

    .mb-150,
    .my-150 {
        margin-bottom: 150px !important;
    }

    .ml-150,
    .mx-150 {
        margin-left: 150px !important;
    }
}