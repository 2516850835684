@import './_variables.scss';

@mixin fontGeneralS {
    font-size: 16px;
    letter-spacing: 2px;

    @media screen and (min-width: 1367px) {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 4px;
    }

    @media screen and (max-width: $maxMobileWidth) {
        line-height: 25px;
    }
}

@mixin fontGeneralS_EN {
    font-size: 16px;
    letter-spacing: normal;

    @media screen and (min-width: 1367px) {
        font-size: 20px;
        line-height: 32px;
    }

    @media screen and (max-width: $maxMobileWidth) {
        line-height: 25px;
    }
}

@mixin fontGeneralM {
    font-size: 25px;
    letter-spacing: 4px;

    @media screen and (min-width: 1367px) {
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 6px;
    }

    @media screen and (max-width: $maxMobileWidth) {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 3px;
    }
}

@mixin fontGeneralM_EN {
    letter-spacing: normal;
    
    @media screen and (min-width: 1367px) {
        letter-spacing: normal;
        font-size: 32px;
    }

    @media screen and (max-width: $maxMobileWidth) {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: normal;
    }
}

@mixin fontGeneralL {
    font-size: 45px;
    letter-spacing: 8px;

    @media screen and (max-width: $maxMobileWidth) {
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 6px;
    }

    @media screen and (min-width: 1367px) {
        font-size: 65px;
        line-height: 75px;
        letter-spacing: 12px;
    }
}

@mixin fontGeneralL_EN {
    font-size: 45px;
    letter-spacing: normal;

    @media screen and (max-width: $maxMobileWidth) {
        font-size: 24px;
        line-height: 35px;
        letter-spacing: normal;
    }

    @media screen and (min-width: 1367px) {
        font-size: 60px;
        line-height: 75px;
        letter-spacing: normal;
    }
}

.font-general-s {
    @include fontGeneralS;
    &.lang-en {
        @include fontGeneralS_EN;
    }
}

.font-general-m {
    @include fontGeneralM;
    &.lang-en {
        @include fontGeneralM_EN;
    }
}

.font-general-l {
    @include fontGeneralL;
    &.lang-en {
        @include fontGeneralL_EN;
    }
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-md-16 {
    font-size: 16px;
}

.line-md-normal {
    line-height: normal;
}

@media screen and (max-width: $maxMobileWidth) {
    .font-20 {
        font-size: 20px!important
    }
}
@media screen and (max-width: 1366px) {
    .text-nowrap-m {
        white-space: nowrap;
    }
    
    .text-normal-m {
        white-space: normal;
    }
}


@media screen and (min-width: 1367px) {
    .text-nowrap-l {
        white-space: nowrap;
    }
    .text-normal-l {
        white-space: normal;
    }
}





