.white-section-bg {
    position: absolute;
    height: 200px;
    width: 100vw;
    top: 100px;
    z-index: -1;
    background-color: $white;
}

.light-blue-section-bg {
    background-color: $lightBlue;
    .white-bg {
        z-index: 0;
        top : 0;
        height: 300px;
    }
}

.white-bg {
    background-color: $white!important;
}

.light-green-bg {
    background-color: $lightGreen!important;
}

.light-grey-bg {
    background-color: $lightGrey!important;
}

.violet-bg {
    background-color: $violet!important;
}

.grey-bg {
    background-color: $grey!important;
}

.light-violet-bg {
    background-color: $light-violet!important;
}

.general-grey-bg {
    background-color: $general-grey!important;
}

.light-purple-bg {
    background-color: $light-purple!important;
}