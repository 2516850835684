.d-inline {
    display: inline-block;
}

.d-none {
    display: none !important;
}
  
.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}



@media screen and (min-width: 768px) {
    .d-md-none {
        display: none!important;
    }
    .d-md-block {
        display: block!important;
    }

    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-md-inline-block {
        display: inline-block!important;
    }

    .d-md-inline {
        display: inline!important;
    }
}

  